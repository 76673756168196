import { Col, Row } from "react-styled-flexboxgrid";

import Layout from "../../components/layout/Layout";
import { Link } from "gatsby";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { StyledGrid } from "../../helpers/helpers.js";

export default () => (
  <Layout>
    <StyledGrid>
      <ScrollAnimation animateIn="fadeInBottom" duration={1}>
        <Row>
          <Col style={{ minHeight: "calc(100vh - 300px)", paddingTop: "64px" }}>
            <h1>Merci !</h1>
            <p>
              Votre message nous a bien été transmis.
              <br />
              Nous reviendrons vers vous dans les meilleurs délais.
            </p>
            <br />
            <Link to={"/"} title="Retour homepage">
              Retourner à l'accueil
            </Link>
          </Col>
        </Row>
      </ScrollAnimation>
    </StyledGrid>
  </Layout>
);
